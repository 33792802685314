<template>
    <!-- 管理员- 报告管理 -->
    <div class="totalReport wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline flex-align-between">
            <div class="formInline-left">
            </div>
            <div class="formInline-right flex-align-between">
                <div class="form-inline-item csp">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span class="csp" @click="create">创建报告</span>
                </div>
            </div>
        </el-form>
        <div class="table_section">
            <template v-if="tableData.length > 0">
                <el-table :data="tableData" border style="width: 100%" v-if="tableData.length > 0">
                    <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod">
                    </el-table-column>
                    <el-table-column prop="title" label="报告名称" align="center">
                    </el-table-column>
                    <el-table-column prop="type" label="报告类型" align="center">
                        <template slot-scope="scope">
                            {{ typeOption.find(item => item.value == scope.row.type).label }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="发布状态" align="center">
                        <template slot-scope="scope">
                            {{ stateList[scope.row.status] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="生成时间" align="center">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center" width="240">
                        <template slot-scope="scope">
                            <el-button @click="viewFile(scope.row)" type="text" size="small">查看
                            </el-button>
                            <el-button @click="editReport(scope.row)" type="text" size="small">编辑
                            </el-button>
                            <el-button @click="reportEditInfo(scope.row)" type="text" size="small">编辑(基本信息)
                            </el-button>
                            <br>
                            <el-button @click="release(scope.row, 2)" type="text" size="small">预发布</el-button>
                            <el-button @click="release(scope.row, 1)" type="text" size="small">发布</el-button>
                            <el-button @click="release(scope.row, 0)" type="text" size="small">取消发布</el-button>
                            <br>
                            <el-button type="text" size="small" @click="stopCreate(scope.row)">停止生成
                            </el-button>
                            <el-button type="text" size="small" @click="deleteProjectFile(scope.row)">删除
                            </el-button>
                            <el-button @click="editFile(scope.row)" type="text" size="small">编辑报告
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                    :pageSizes="[10, 20, 30]" @pagination="handlePageChange">
                </pagination>
            </template>
            <el-empty description="暂无报告" v-else></el-empty>
        </div>
        <el-dialog :title="titleType[subForm.status]" :visible.sync="dialogVisible" width="30%">
            <div class="submit-box">
                <span class="sub-title">是否{{ titleType[subForm.status] }}名为“{{ title }}”的报告？</span>
                <el-form :model="subForm" label-position="left" label-width="80px" :rules="rules" ref="ruleForm">
                    <el-form-item label="标题名称" size="small" prop="name">
                        <el-input v-model="subForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="顺序" size="small" prop="order">
                        <el-input v-model="subForm.order"></el-input>
                    </el-form-item>
                    <el-form-item label="发布模型">
                        <el-radio-group v-model="subForm.modelType">
                            <el-radio label="0">kimi</el-radio>
                            <el-radio label="1">通义千问</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="publishReport">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import upload from '../components/upload-file.vue';
import {
    cloneDeep
} from 'lodash/lang'
import Template from '../../td_test/template.vue';

export default {
    components: {
        upload,
        Template
    },
    data() {
        return {
            activeName: "",
            rules: {
                name: {
                    required: true,
                    message: "请输入标题名称",
                    trigger: "blur"
                },
                order: {
                    required: true,
                    message: "请输入顺序",
                    trigger: "blur"
                },
            },
            tableData: [],
            pages: { //分页
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            showCreateDialog: false,
            createForm: {
                name: undefined,
                identifier: undefined,
                type: 4,
            },
            dialogTitle: '上传周报',
            editIdentifierList: [],
            typeOption: [
                {
                    value: 0,
                    label: '专家报告'
                },
                {
                    value: 1,
                    label: '盟市/教委总结'
                },
                {
                    value: 3,
                    label: '个人总结'
                },
                {
                    value: 4,
                    label: '前后测报告（原始数据）'
                },
                {
                    value: 5,
                    label: '自定义（个人）'
                },
                {
                    value: 6,
                    label: '自定义（全体）'
                },
                {
                    value: 7,
                    label: '前后测报告（ai分析）'
                },
            ],
            dialogVisible: false,
            subForm: {
                name: '',
                order: null,
                status: 0,
                modelType:'0'
            },
            title: '',
            reportId: null,
            stateList: {
                0: '未发布',
                1: '已发布',
                2: '预发布'
            },
            titleType: {
                0: '预发布',
                1: '发布',
                2: '发布'
            }
        };
    },
    methods: {
        indexMethod(index) {
            return this.pages.currentPage > 1 ?
                (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                index + 1
        },
        // 翻页
        handlePageChange(data) {
            this.pages.currentPage = data.pageIndex
            this.pages.pageSize = data.pageSize
            this.getReportList();
        },
        // 获取报告列表
        async getReportList() {
            let pramas = {
                page: this.pages.currentPage,
                limit: this.pages.pageSize,
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportList(pramas);
            console.log('列表数据', resData.data);
            this.tableData = resData.data.records
            this.pages.total = resData.data.total
        },
        // 获取报告类型Option
        // async getReportOption() {
        //     let pramas = {
        //         projectId: this.$store.state.user.currentProject.id,
        //     }
        //     let resData = await this.$Api.Project.getReportOption(pramas);
        //     console.log('选项数据', resData.data);
        //     this.typeOption = resData.data.type
        // },
        // 创建报告
        create() {
            this.$router.push(`/manager/report/create/${this.$store.state.user.currentProject.id}?breadNumber=4`)
        },
        // 查看报告
        viewFile(row) {
            console.log('row:', row);
            if (row.type == 7) {
                this.$router.push({
                    path: `/WjxReport?breadNumber=4`,
                    query: {
                        reportId: row.id,
                        reportType: row.type,
                        title: row.title,
                        isView: true,
                        vid: row.surveyIds
                    }
                })
            } else {
                this.$router.push({
                    path: `/manager/report/view_kimi?breadNumber=4`,
                    query: {
                        reportId: row.id,
                        reportType: row.type,
                        title: row.title,
                        isEdit: false,
                        status: row.status
                    }
                })
            }
        },
        // 编辑报告
        editFile(row) {
            if (row.type == 7) {
                this.$router.push({
                    path: `/WjxReport?breadNumber=4`,
                    query: {
                        reportId: row.id,
                        reportType: row.type,
                        title: row.title,
                        isEdit: true,
                        rootMsgId: row.surveyIds
                    }
                })
            } else {
                this.$router.push({
                    path: `/manager/report/view_kimi?breadNumber=4`,
                    query: {
                        reportId: row.id,
                        reportType: row.type,
                        title: row.title,
                        isEdit: true
                    }
                })
            }

        },
        release(row, type) {
            // type 0-取消发布 1-发布 2-预发布
            this.title = row.title;
            this.reportId = row.id;
            if (type == 0) {
                this.$confirm(`是否取消发布${this.title}报告?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.unpublish()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.dialogVisible = true;
                this.subForm.name = row.publishTitle ? row.publishTitle : '';
                this.subForm.order = row.publishSort ? row.publishSort : null;
                if (type == 1) {
                    this.subForm.status = 1;
                } else if (type == 2) {
                    this.subForm.status = 2;
                }
            }
        },
        // 删除
        deleteProjectFile(row) {
            this.$confirm(`是否删除名为“${row.title}”的报告？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let params = {
                        id: row.id
                    }
                    console.log(params);
                    this.$Api.Project.delReport(params).then((resData) => {
                        console.log(resData);
                        if (resData.code == 200) {
                            this.$message({
                                message: "删除成功",
                                type: "success"
                            });
                            this.getReportList();
                        } else {
                            this.$message.error(resData.msg);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "取消删除"
                    });
                });
        },
        // 编辑报告(重新生成)
        editReport(row) {
            this.$router.push({
                path: `/manager/report/edit/${row.id}?breadNumber=4`,
            })
        },
        // 编辑报告基本信息(不重新生成)
        reportEditInfo(row) {
            this.$router.push({
                path: `/manager/report/edit/${row.id}?breadNumber=4&isCreate=false`,
            })
        },
        //停止生成报告
        async stopCreate(row) {
            console.log('row:', row)
            let data = {
                reportId: row.id,
                type: row.modelType ? row.modelType : 0
            }
            let resData = await this.$Api.Project.shopReportResult(data);
            console.log('停止生成报告:', resData);
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
        },
        // 发布报告
        publishReport() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    console.log('发布报告');
                    let params = {
                        id: this.reportId,
                        status: this.subForm.status,
                        publishTitle: this.subForm.name,
                        publishSort: this.subForm.order,
                        publishModelType:this.subForm.modelType
                    }
                    this.$Api.Project.getReportPublish(params).then((resData) => {
                        if (resData.code == 200) {
                            this.$message({
                                message: "发布成功",
                                type: "success"
                            });
                            this.subForm.name = '',
                                this.subForm.order = null,
                                this.dialogVisible = false;
                            this.getReportList();
                        } else {
                            this.$message.error('发布失败', resData.msg);
                            this.subForm.name = '',
                                this.subForm.order = null,
                                this.dialogVisible = false;
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 取消发布
        async unpublish() {
            let data = {
                id: this.reportId,
                status: 0
            }
            let resData = await this.$Api.Project.getReportPublish(data);
            console.log('取消发布', resData);
            this.$message({
                type: 'success',
                message: '取消成功!'
            });
            this.getReportList();
        }
    },
    mounted() {
        this.getReportList();
        // this.getReportOption();
        Vue.set(this.createForm, 'projectId', this.$store.state.user.currentProject.id);
    },
}
</script>

<style lang="less" scoped>
@import "../../manage/less/table.less";

.totalReport {
    padding: 20px 25px;
    box-sizing: border-box;

    // /deep/ .el-dialog {
    //     border-radius: 10px;

    //     .el-dialog__header {
    //         border-bottom: 1px solid #E4E4E4;
    //         padding: 15px 31px 10px;
    //     }

    //     .el-dialog__title {
    //         font-size: 15px;
    //         color: #333;

    //         &::before {
    //             content: '';
    //             width: 5px;
    //             height: 17px;
    //             background-color: #01A2FD;
    //             display: inline-block;
    //             margin-right: 14px;
    //             vertical-align: -3px;
    //             border-radius: 3px;
    //         }
    //     }

    //     .el-dialog__headerbtn {
    //         top: 15px;
    //     }

    //     .btns-box {
    //         text-align: center;
    //     }

    //     .submit {
    //         background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
    //         border-radius: 25px;
    //         color: #fff;
    //         line-height: 35px;
    //         padding: 0 63px;
    //         cursor: pointer;
    //         border: 0;

    //         &:hover {
    //             background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
    //         }
    //     }
    // }

    .table_section {
        margin-top: 15px;
    }

    .submit-box {
        width: 80%;

        .sub-title {
            display: block;
            margin-bottom: 20px;
        }
    }
}
</style>